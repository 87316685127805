<template>
  <modal
      @close="$emit('close')"
      class="custom-popup w100"
  >
    <template slot="header">
     <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
       <div class="admin-edit" @click="editTranslate([
            'inventory_NewInventarization',
          ])"></div>
       {{ $t(`inventory_HasChanges.localization_value.value`) }}
     </div>
    </template>
    <template slot="body">
      <p class="negative-balance-popup__subtitle">
        {{ $t(`inventory_HasChangesDesc.localization_value.value`) }}
      </p>
      <div class="custom-popup__content">
          <InventoryTable
              :Inventory="Inventory"
              :filterType="filterType"
              :changesPopup="true"
          />
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_submit',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
        </span>

        <MainButton
            class="scoped-width"
            :value="$t('common_submit.localization_value.value')"
            @click.native="$emit('close')"
        />
<!--            @click.native="createInventoryRequest"-->
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  // import InventoryTable from "../../components/InventoryEdit/InventoryEditAdmin/InventoryTable/InventoryTable";


  export default {
    name: "ChangesInventoryPopup",
    components: {
      // InventoryTable,
      InventoryTable: () => import('../../components/InventoryEdit/InventoryEditAdmin/InventoryTable/InventoryTable'),
      Modal,
      MainButton,
    },

    props: {
      Inventory: Object,
      filterType: {
        type: Object,
        default: null,
      },
    },

    data(){
      return{

      }
    },

    mounted() {
      console.log(2222);
      // console.log(InventoryTable);
    },

    methods: {



    },

  }

</script>

<style scoped>


.custom-popup.w100 >>> .modal-component__inner {
  max-width: 80%;
}

</style>
